import React, { useState } from 'react';
import '../../styles/MainNotice/Profile.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faEnvelopeOpenText  } from '@fortawesome/free-solid-svg-icons';
import { useMsal } from '@azure/msal-react'; 

const Profile = ({toggleSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { instance } = useMsal(); // Get the MSAL instance
  const activeAccount = instance.getActiveAccount(); // Get the active account

  const initials = activeAccount ? `${activeAccount.idTokenClaims.given_name.charAt(0)}${activeAccount.idTokenClaims.family_name.charAt(0)}` : "U"; 
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/customerType",
      });
    } catch (error) {
      console.error('Error logging out:', error);
      // Optionally, show an error message to the user
    }
  };


  return (
    
    <div className="profile-header">
    <button className="sidebar-toggle" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faEnvelopeOpenText} />
    </button>
    <div className="profile-container">
      <div className={`profile-circle ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
        {initials}
      </div>
      {isOpen && (
        <div className="dropdown">
          <button className="logout-button" onClick={handleLogout}>
            <FontAwesomeIcon icon={faRightFromBracket}  className="logout-icon"/>Logout
          </button>
        </div>
      )}
    </div>
    </div>
  );
};
export default Profile;
