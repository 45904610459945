import React, { useState } from 'react';


export const SelectCustomerType = ({ onSelect }) => {
    const [selectedType, setSelectedType] = useState('');
  
    const handleClick = (type) => {
      setSelectedType(type);  // Update the selected type
      onSelect(type);  // Call the onSelect prop function passed from the parent
    };
  
    return (

      <div className = "select-container">
        <button
          className={`customer-button ${selectedType === 'business' ? 'active' : ''}`}
          onClick={() => handleClick('business')}
        >
          Register Your Business
        </button>
        <button
          className={`customer-button ${selectedType === 'subscriber' ? 'active' : ''}`}
          onClick={() => handleClick('subscriber')}
        >
          Subscribe To Notifications
        </button>
      </div>
    );
  };
  
  export default SelectCustomerType;