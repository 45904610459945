import React, { useEffect } from 'react';

const SuccessIconWithBurst = () => {
  useEffect(() => {
    const createParticles = () => {
      const container = document.querySelector('.success-icon');
      if (container) {
        // Create 10 particles
        for (let i = 0; i < 10; i++) {
          const particle = document.createElement('div');
          particle.className = 'particle';

          // Set random directions for each particle
          const angle = (i * (2 * Math.PI)) / 10; // Angle in radians (divide full circle into 10 parts)
          const distance = 50; // Set distance for particle travel

          // Calculate position relative to the center of the icon
          const x = Math.cos(angle) * distance + 'px';
          const y = Math.sin(angle) * distance + 'px';

          // Set the CSS variables to control particle position
          particle.style.setProperty('--x', x);
          particle.style.setProperty('--y', y);

          // Append the particle to the container
          container.appendChild(particle);

          // Remove particle after animation
          particle.addEventListener('animationend', () => {
            container.removeChild(particle);
          });
        }
      }
    };
    createParticles();
  }, []);

  return (
    <div className="success-icon">
      <div className="success-checkmark-icon"></div>
    </div>
  );
};

export default SuccessIconWithBurst;
