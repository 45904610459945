import React, { useState } from 'react';
import '../../styles/MainNotice/SelectNoticeType.css'; 

const SelectNoticeType = ({ value, onValueChange, placeholder, children }) => {
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionValue) => {
    onValueChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}  role="button"  tabIndex="0" >
        <span className="dropdown-text">{value || placeholder}</span>
        <span className={`custom-arrow ${isOpen ? 'rotate' : ''}`}></span>
      </div>
      {isOpen && (
        <ul className="dropdown-options">
          {React.Children.map(children, (child) => (
            <li
              key={child.props.value}
              className="dropdown-option"
              onClick={() => handleOptionClick(child.props.value)}
            >
              {child.props.children}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SelectNoticeType.Option = ({ value, children }) => {
  return <>{children}</>;
};

export default SelectNoticeType;
