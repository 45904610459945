import React, { useState, useEffect, useRef   } from 'react';
import JSConfetti from 'js-confetti';
import { useLocation } from 'react-router-dom';
import { SubmitButton, PhoneNumber, TermsAndConditions, UserForm } from '../components/Subscribe'
import '../styles/Subscribe/MasterStyle.css';


const Subscribe = () => {
  const location = useLocation(); 
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const customerKey = queryParams.get('customerKey'); // Get customerKey from query parameters
  const zipCode = queryParams.get('zip'); // Get zip from query parameters
  const businessName = queryParams.get('businessName');

  useEffect(() => {
    document.body.style.overflowY = 'auto'; // Enable scrolling for the Subscribe page
    return () => {
      document.body.style.overflowY = ''; // Reset styles when the component unmounts
    };
  }, []);

  useEffect(() => {
    // Fetch notification types from your API
    const fetchNotificationTypes = async () => {
      try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe-list/${customerKey}/notification-types`);
        const data = await response.json();

        setNotificationTypes(data);
         // Initialize selected notifications state
         const initialSelected = data.reduce((acc, type) => {
            acc[type.fstr_notification_type] = false; // Initialize with false (not selected)
            return acc;
        }, {});
        setSelectedNotifications(initialSelected);

         setIsLoaded(true);

      } catch (error) {
        console.error('Error fetching notification types:', error);
      }
    };

    if (customerKey) {
      fetchNotificationTypes();
  }

}, [customerKey, zipCode]); // Re-fetch if customerKey or zipCode changes


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSelectedNotifications((prev) => ({ ...prev, [name]: checked }));
    };

    const handleTermsAccept = (checked) => {
        setIsChecked(checked);
        if (checked) {
            setError(''); // Clear error when terms are accepted
        }
    };

  const handleSubscribe = async (e) => {
        e.preventDefault(); 

        // Basic phone number validation

        const strippedPhoneNumber = phoneNumber.replace(/\D/g, ''); 
         const phoneRegex = /^[0-9]{10}$/; // Adjust regex for your needs
         if (!phoneRegex.test(strippedPhoneNumber)) {
            setError('Please enter a valid phone number.');
         return;
        }

        // Check if at least one notification is selected
        if (!Object.values(selectedNotifications).some(value => value)) {
        setError("At Least One Subscription Is Required");
        return;
        }

        if (!email) {
          setError('Please enter your email address.');
          return;
      }

        if (!isChecked) {
            setError('You must accept the Terms and Conditions to proceed.');
            return;
        }

        setIsSending(true);
        setError('');

        // API call to subscribe the user
        // Convert selected notifications (object) into an array of selected notification types
        const selectedNotificationKeys = Object.keys(selectedNotifications)
        .filter((key) => selectedNotifications[key])  // Only keep the selected ones
        .map((key) => {
            const notificationType = notificationTypes.find((type) => type.fstr_notification_type === key);
            return notificationType ? notificationType.flng_notification_type_key : null;
        })
        .filter((key) => key !== null); // Remove any null values

        console.log('Selected Notifications:', selectedNotifications);
        console.log('Selected Notification Keys:', selectedNotificationKeys);
   
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe-submit`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phoneNumber,
                firstName,
                lastName,
                email,
                selectedNotifications: selectedNotificationKeys,
              }),
          });
    
          if (response.ok) {

            setIsSubscribed(true);    
            confettiRef.current.addConfetti({
              confettiRadius: 6,
              confettiNumber: 400,
            });

          } else {      

            throw new Error('Subscription failed. Please try again later.');
          }
        } catch (error) {
          setError(error.message);
        }
  };

  const canvasRef = useRef();
  const confettiRef = useRef();

  useEffect(() => {
    confettiRef.current = new JSConfetti({ canvas: canvasRef.current });
  }, []);
  
  return (
    <div>
    {isSubscribed ? (
      <div className="subscribed">
        <p> 
          {"Thank you for subscribing! ".split("").map((char, index) => (
            <span key={`sub-${index}`} style={{ "--char-index": index }}>
              {char === " " ? "\u00A0" : char}
            </span>
          ))}
        </p>
        <p>
          {"You'll receive notifications soon.".split("").map((char, index) => (
            <span key={`notify-${index}`} style={{ "--char-index": index }}>
              {char === " " ? "\u00A0" : char}
            </span>
          ))}
        </p>
        <canvas className="confetti-canvas" ref={canvasRef} />
      </div>
    ) : (
      <div className="subscription-container">
      <div className="header">
          <h2>Subscribe to Notifications for {businessName}</h2>
          <p>Stay updated with the latest news and offers!</p>
      </div>
      <form onSubmit={handleSubscribe}>
          <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
              />
          </div>
          <div   className={`checkbox-group ${isLoaded ? 'loaded' : 'loading'}`}>
              {notificationTypes.map((type) => (
                  <div key={type.flng_notification_type_key}>
                      <input
                          type="checkbox" 
                          id={type.fstr_notification_type}
                          name={type.fstr_notification_type}
                          checked={selectedNotifications[type.fstr_notification_type] || false}
                          onChange={handleCheckboxChange}
                      />
                      <label htmlFor={type.fstr_notification_type}>{type.fstr_notification_type}</label>
                  </div>
              ))}
          </div>
          <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
              />
          </div>
          <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input
                  type="text"
                  id="last-name"
                  name="last-name"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
              />
          </div>
          <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
              />
          </div>
          <div className="terms-container">
          <input
              type="checkbox"
              id="terms"
              name="terms"
              checked={isChecked}
              onChange={(e) => handleTermsAccept(e.target.checked)}
          />
          <label htmlFor="terms">I accept the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></label>
      </div>
          <button type="submit" className="subscribe-button" disabled={isSending}>Subscribe</button>
      </form>
    
      {error && <p className="error-message">{error}</p>}
  </div>
    )}
  </div>
);
};

export default Subscribe;
