import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion'
import { SelectNoticeType, BuzzMessage, Profile, SentNotifications, Sidebar, AddNoticeType, RemoveNoticeType, BusinessInfoForm, SubscriptionQR, StripePaymentForm, TextCounter  } from '../components/MainNotice';
import '../styles/MainNotice/MainNotice.css'; 

const MainNotice = () => {

  const [customerKey, setCustomerKey] = useState(null);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [selectedTypeKey, setSelectedTypeKey] = useState('');
  const [selectedTypeLabel, setSelectedTypeLabel] = useState('');
  const [selectedRemoveTypeKey, setSelectedRemoveTypeKey] = useState('');
  const [selectedRemoveTypeLabel, setSelectedRemoveTypeLabel] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [sentNotifications, setSentNotifications] = useState([]); // State for storing sent messages
  const [loadingTypes, setLoadingTypes] = useState(true); // Loading state for fetching notification types
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false); // Loading animation state
  const [isSentNotificationsVisible, setSentNotificationsVisible] = useState(false); // State for sidebar visibility
  const [isSubscriptionVisible, setSubscriptionVisible] = useState(false); // State for sidebar visibility
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const [showNoticeTypeOptions, setShowNoticeTypeOptions] = useState(false);
  const [isAddNoticeTypeVisible, setShowAddNoticeType] = useState(false);
  const [isRemoveNoticeTypeVisible, setShowRemoveNoticeType] = useState(false);
  const [isBusinessInfoFormVisible, setShowBusinessInfoForm] = useState(false);
  const [isQRCodeVisible, setShowQRCode] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const textCounterRef = useRef();

  const [theme, setTheme] = useState('light'); // State for theme selection

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const toggleSideBar = () => {
    setSideBarOpen(!isSideBarOpen);
  };

  const toggleSentNotifications = () => {
     setSentNotificationsVisible(!isSentNotificationsVisible);
  };

  const toggleNoticeTypeOptions = () => {
    setShowNoticeTypeOptions(!showNoticeTypeOptions);
  };

  const toggleAddNoticeType = () => {
    setShowAddNoticeType(!isAddNoticeTypeVisible);
  };

  const  toggleRemoveNoticeType = () => {
    setShowRemoveNoticeType(!isRemoveNoticeTypeVisible);
  };

  const  toggleBusinessInfoForm = () => {
    setShowBusinessInfoForm(!isBusinessInfoFormVisible);
  };

  const  toggleQRCode = () => {
    setShowQRCode(!isQRCodeVisible);
  };

  const  toggleSubscription = () => {
    setSubscriptionVisible(!isSubscriptionVisible);
  }; 

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden'; // Disable scrolling for the Main Notice page
    return () => {
      document.body.style.overflowY = ''; // Reset styles when the component unmounts
    };
  }, []);


  useEffect(() => {
        const queryCustomerKey = query.get('customerKey');
        if (queryCustomerKey) {
            setCustomerKey(queryCustomerKey); // Use customerKey from query if available
        } else {
            // Fetch customerKey from the session if not in query
            const fetchCustomerKeyFromSession = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/get-session-data`, {
                        method: 'GET',
                        credentials: 'include', // Include cookies for session retrieval
                    });
                  
                    if (response.ok) {
                        const data = await response.json();
                        setCustomerKey(data.customerKey);
                    } else {
                        console.error('Failed to fetch customerKey from session:', response.status);
                    }
                } catch (error) {
                    console.error('Error fetching customerKey from session:', error);
                }
            };
            fetchCustomerKeyFromSession();
        }
    }, [query]);

    const fetchNotificationTypes = async () => {
      setLoadingTypes(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notification-types`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' // Include cookies with request
      });

      // Check if the response is OK (status code 200)
      if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

      const data = await response.json();
      setNotificationTypes(data);
      console.log("Notification Type Data: ",data);
    } catch (error) {
      console.error('Error fetching notification types:', error);
    } finally {
      setLoadingTypes(false);
    }
    };

  const setSelectedType = (selectedKey) => {
    setSelectedTypeKey(selectedKey);

    // Find and set the label for the selected key
    const selectedType = notificationTypes.find(
      (type) => type.flng_notification_type_key === Number(selectedKey)
    );
    setSelectedTypeLabel(selectedType ? selectedType.fstr_notification_type : '');
  };


  const setSelectedRemoveType = (selectedKey) => {

    setSelectedRemoveTypeKey(selectedKey);

    // Find and set the label for the selected key
    const selectedRemoveType = notificationTypes.find(
      (type) => type.flng_notification_type_key === Number(selectedKey)
    );
    setSelectedRemoveTypeLabel(selectedRemoveType ? selectedRemoveType.fstr_notification_type : '');
  };

  useEffect(() => {
    if (selectedTypeKey) {
    const fetchSentNotifications = async () => {
      setIsLoadingNotifications(true);
      setSentNotifications([]); // Clear notifications to trigger the reloading animation
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dash-sent-notifications?selectedTypeKey=${selectedTypeKey}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include' // Include cookies with request
        });
  
        // Check if the response is OK (status code 200)
        if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
  
        const data = await response.json();

        for (let i = 0; i < data.length; i++) {
          setTimeout(() => {
              setSentNotifications(prev => [...prev, { ...data[i], isVisible: true }]);
          }, i * 200); // 200ms delay between each notification
      }
      
      } catch (error) {
        console.error('Error fetching sent notifications:', error);
      } finally {
        setIsLoadingNotifications(false);
      }
    };
  
    fetchSentNotifications();
  }

  }, [selectedTypeKey]);


  const handleSend = async () => {
    if (!selectedTypeKey  || !message) {
      alert('Please select a notification type and enter a message.');
      return;
    }

    const selectedTypeDetails = notificationTypes.find(
      (type) => type.flng_notification_type_key === Number(selectedTypeKey)
    );

    setIsSending(true);

    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          flngnotificationtypekey: selectedTypeKey, // Use the selected type key
          fstrnotificationtype: selectedTypeDetails.fstr_notification_type,
          fstrdescription: message, // Message input from the user
        }),
        credentials: 'include'
      });

      if (response.ok) {
        alert('Notification sent successfully!');
        setMessage(''); // Clear the message after successful send
        setSelectedTypeKey('');
        setSelectedTypeLabel(''); // Optionally clear the selected type
        textCounterRef.current.updateCounter();
      } 
      else { throw new Error('Failed to send notification'); }
    } catch (error) {
      console.error('Error sending notification:', error);
      alert('Failed to send notification. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscription-status?customerKey=${customerKey}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setSubscriptionStatus(data.status); // Assuming the API returns the subscription status as a 'status' field
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };
  
  useEffect(() => {
    if (customerKey) {
      fetchNotificationTypes();
      fetchSubscriptionStatus();
    }
  }, [customerKey]);
  

  const handleUpdateSentNotifications = (notifications) => {
    setSentNotifications(notifications);
  };

  const handleRefreshNotificationType = async () => {
    // After removing the notification type, refresh the list
    await fetchNotificationTypes();
  };

  // Update input value with the message state
  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    className={`p-8 ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}
  >

    <div className="dashboard-container">
      <header className="profile-header">
        <Profile />
          <TextCounter ref={textCounterRef} customerKey={customerKey} />
      </header>
      <Sidebar 
        toggleSideBar={toggleSideBar} 
        isSideBarOpen={isSideBarOpen} 
        toggleSentNotifications={toggleSentNotifications}
        toggleNoticeTypeOptions={toggleNoticeTypeOptions} 
        showNoticeTypeOptions={showNoticeTypeOptions} 
        toggleAddNoticeType={toggleAddNoticeType} 
        toggleRemoveNoticeType={toggleRemoveNoticeType} 
        toggleBusinessInfoForm={toggleBusinessInfoForm}
        toggleQRCode={toggleQRCode}
        toggleSubscription={toggleSubscription}
      />

      {/*
     <div className="theme-selector">
        <label htmlFor="theme">Select Theme: </label>
        <select id="theme" value={theme} onChange={handleThemeChange}>
          <option value="light">Light Mode</option>
          <option value="dark">Dark Mode</option>
        </select>
      </div>*/}

      <BusinessInfoForm  isBusinessInfoFormVisible={isBusinessInfoFormVisible} 
        toggleBusinessInfoForm={toggleBusinessInfoForm}/>
     <SentNotifications 
        messages={sentNotifications} 
        isSentNotificationsVisible={isSentNotificationsVisible} 
        toggleSentNotifications={toggleSentNotifications} 
        onUpdateSentNotifications={handleUpdateSentNotifications}
      />
      <AddNoticeType 
      isAddNoticeTypeVisible={isAddNoticeTypeVisible} 
      toggleAddNoticeType={toggleAddNoticeType}
      onAddSuccess={handleRefreshNotificationType}
      />
      <RemoveNoticeType 
          isRemoveNoticeTypeVisible={isRemoveNoticeTypeVisible} 
          toggleRemoveNoticeType={toggleRemoveNoticeType} 
          value={selectedRemoveTypeLabel}
          noticeKey={selectedRemoveTypeKey}
          onValueChange={setSelectedRemoveType}
          placeholder="Select Notification Type"
          onRemoveSuccess={handleRefreshNotificationType}
        >
           {notificationTypes.map((type) => (
            <RemoveNoticeType.Option key={type.flng_notification_type_key} value={type.flng_notification_type_key}>
              {type.fstr_notification_type}
            </RemoveNoticeType.Option>
          ))}
        </RemoveNoticeType>

        <SubscriptionQR 
          isQRCodeVisible={isQRCodeVisible} 
          toggleQRCode={toggleQRCode}
       />
        <StripePaymentForm  
          isSubscriptionVisible={isSubscriptionVisible} 
          toggleSubscription={toggleSubscription}
       />

      {/* Content Area */}
      <div className="content-container">
        <SelectNoticeType
          value={selectedTypeLabel}
          onValueChange={setSelectedType}
          placeholder="Select Notification Type"
        >
          {notificationTypes.map((type) => (
            <SelectNoticeType.Option key={type.flng_notification_type_key} value={type.flng_notification_type_key}>
              {type.fstr_notification_type}
            </SelectNoticeType.Option>
          ))}
        </SelectNoticeType>

        <div className="message-display-container">
          {isLoadingNotifications ? (
            <p>Loading notifications...</p>
          ) : sentNotifications.length === 0 ? (
            <p>No notifications sent yet.</p>
          ) : (
            sentNotifications.map((notification, index) => (
              <div key={index}  className={`dash-notification-item ${notification.isVisible ? 'visible' : ''}`}>
                <div className="dash-notification-content">
                  <p className="dash-notification-subject">{notification.fstr_subject}</p>
                  <p className="dash-notification-description">{notification.fstr_description}</p>
                  <span className="dash-timestamp">{new Date(notification.fdtm_sent).toLocaleString()}</span>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="message-input-container">
          <BuzzMessage
            placeholder="Enter your message..."
            value={message}
            onChange={handleChange}
            className="textbox"
            onClick={handleSend}
            disabled={isSending}
          >
            {isSending ? 'Sending...' : 'Send'}
          </BuzzMessage>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default MainNotice;
