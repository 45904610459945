import React from 'react';
import '../../styles/Subscribe/TermsAndConditions.css';

const TermsAndConditions = () => {
  

    return (
        <div className="terms-container">
            <h2>Terms and Conditions</h2>
            <div className="terms-content">
                <h2>Agreement to Receive Text Messages</h2>
                <p>By providing your mobile number, you agree that Bulk Buzz may send you periodic SMS or MMS messages containing important information, updates, deals, and specials.</p>
                <br></br>
                <ul>
                    <li>You will receive up to 5 messages per month.</li>
                    <li>You may unsubscribe at any time by texting the word <strong>STOP</strong> to (555) 555-5555. You may receive a subsequent message confirming your opt-out request.</li>
                    <li>For help, send the word <strong>HELP</strong> to (555) 555-5555.</li>
                    <li>Message and data rates may apply.</li>
                    <li>United States Participating Carriers Include AT&T, T-Mobile®, Verizon Wireless, Sprint, Boost, U.S. Cellular®, MetroPCS®, InterOp, Cellcom, C Spire Wireless, Cricket, Virgin Mobile, and others.</li>
                    <li>T-Mobile is not liable for delayed or undelivered messages.</li>
                    <li>You agree to notify us of any changes to your mobile number and update your account with us to reflect this change.</li>
                    <li>Data obtained from you in connection with this SMS service may include your cell phone number, your carrier’s name, and the date, time, and content of your messages, as well as other information that you provide. We may use this information to contact you and to provide the services you request from us.</li>
                    <li>By subscribing or otherwise using the service, you acknowledge and agree that we will have the right to change and/or terminate the service at any time, with or without cause and/or advance notice.</li>
                </ul>
                <p>If you have any questions, please contact Bulk Buzz at (402) 555-5555.</p>
                <h3>Will I be charged for the text messages I receive?</h3>
                <p>Though Bulk Buzz will never charge you for the text messages you receive, depending on your phone plan, you may see some charges from your mobile provider. Please reach out to your wireless provider if you have questions about your text or data plan.</p>
                <h3>Data Collection</h3>
                <p>At Bulk Buzz, we value your privacy and strive to protect your personal information. We collect personal data, including activity data, which may be linked to your account. Our systems use technologies such as cookies, web beacons, and unique device identifiers to enhance your experience. We also log information like your browser type, operating system, and IP address.</p>
                <p>When you use our service, we may collect personally identifiable information that you provide to us, such as your name, address, phone number, or email address. With your consent, we may access additional personal information on your device, such as your contacts or calendar, to deliver our services. If authorized, we may also gather profile information from social media platforms like Facebook.</p>
                <p>Our systems may associate this personal information with your activity while using our service, such as pages you view or actions you take.</p>
                <p>We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us to rectify this.</p>
                <h3>Location Data</h3>
                <p>We may collect and share anonymous location data. To customize our service, we and our partners may collect precise location data, including the real-time geographic location of your device. This information is collected anonymously and is used solely to enhance our service. We will obtain your consent upon your first use of the service.</p>
                <h3>Access to Personal Data</h3>
                <p>You can request access to or deletion of your personal data. You may log into your Bulk Buzz account to view or delete any personally identifiable information we have stored, such as your name, address, or phone number. Alternatively, you can contact us by email to request this information.</p>
                <h3>Data Retention</h3>
                <p>We may retain your data indefinitely as required to provide our services.</p>
                <h3>Sharing of Information</h3>
                <p>We may share your personal data with trusted partners to provide you with relevant advertising, offers, or services. This may include sharing your personally identifiable information, such as your name or phone number.</p>
                <p>As a California resident, you are entitled (at no charge, and no more than once annually) to request information about how we may have shared your information for direct marketing purposes. Please contact us for more details: <a href="mailto:rays7777@tattoo.com">rays7777@tattoo.com</a>.</p>
                <h3>Advertising and Tracking</h3>
                <p>Advertising companies may collect anonymous data about your interests to customize content and advertising both on our platform and across other websites and applications. Interest and location data may be linked to your device but will not be associated with your identity.</p>
                <h3>Contact Us</h3>
                <p>If you have any questions or concerns regarding our privacy policies, please feel free to reach out to us at: <a href="mailto:rays7777@tattoo.com">rays7777@tattoo.com</a>.</p>
                <h3>Vendors and Service Providers</h3>
                <p>To serve you better, we may share your personal and anonymous information with vendors and contractors. Their access to information is limited to the necessary purposes and is governed by agreements requiring them to maintain confidentiality. While we ensure our vendors take reasonable steps to protect your data, complete data security cannot be guaranteed.</p>
                <p>Analytics companies may access anonymous data (such as your IP address or device ID) to help us understand how our services are used. They will only use this data on our behalf and do not share it in any identifiable form.</p>
                <h3>Special Situations</h3>
                <p>There may be instances where we need to disclose your information to third parties, such as: (1) with your consent, (2) as required by law, (3) to protect our rights or property, or (4) in the event of a merger, acquisition, or sale of assets. Your consent is not required for these disclosures, but we will attempt to notify you when permissible.</p>
                <h3>Changes to Our Terms</h3>
                <p>Please note that our privacy policy may change from time to time. We encourage you to review this document regularly for updates.</p>
            </div>          
        </div>
    );
};    

export default TermsAndConditions;
