import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faMinus } from '@fortawesome/free-solid-svg-icons';
import '../../styles/MainNotice/RemoveNoticeType.css'; 

const RemoveNoticeType = ({ value, noticeKey, onValueChange, placeholder, children, isRemoveNoticeTypeVisible, toggleRemoveNoticeType, onRemoveSuccess }) => {

    const [removeType, setRemoveType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State to manage success message visibility
    const [showFailMessage, setShowFailMessage] = useState(false); // State to manage fail message visibility
    const [showBlankNotificationMessage, setShowBlankNotificationMessage] = useState(false); // State to manage fail message visibility
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const handleOptionClick = (optionValue) => {
      onValueChange(optionValue);
      setIsOpen(false);
    };


    useEffect(() => {
        const container = containerRef.current;
    
        if (isRemoveNoticeTypeVisible) {
          container.addEventListener('transitionend', handleTransitionEnd);
        } else {
          container.style.overflow = 'hidden'; // Set overflow to hidden when collapsing
        }
    
        return () => {
          container.removeEventListener('transitionend', handleTransitionEnd);
        };
      }, [isRemoveNoticeTypeVisible]);
    
      const handleTransitionEnd = () => {
        if (isRemoveNoticeTypeVisible && containerRef.current) {
          containerRef.current.style.overflow = 'visible'; // Set overflow to visible when expanded
        }
      };

    const removeNotificationType = async () => {

        if (noticeKey === undefined) {
            setShowBlankNotificationMessage(true);   //Show Blank Notification Message

            setTimeout(() => {
                setShowBlankNotificationMessage(false);
            }, 3000);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/remove-notice-type`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ notificationTypeKey: noticeKey }),
                credentials: 'include', // Include credentials with the request
            });

            console.log("remove-Notice-Type Response: ", response)

            if (response.ok) {
                const removedType = await response.json();
                setRemoveType('');
                setShowSuccessMessage(true); // Show success message

                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 3000);

                onRemoveSuccess();
                
            } else if (response.status === 400) {
                setShowFailMessage(true); // Show Fail message

                setTimeout(() => {
                    setShowFailMessage(false);
                }, 3000);
               
            } else {
                console.error("Failed to remove notification type");
            }
        } catch (error) {
            console.error('Error remove notification type:', error);
        }
    };

    const handleToggleRemoveNoticeType = () => {
        setRemoveType(''); // Clear input when toggling the container
    };

    return (

        <div ref={containerRef} className={`remove-notice-type-container ${isRemoveNoticeTypeVisible ? `expanded` : `collapsed`}`}>
                <button className="remove-notice-type-header" onClick={() => {toggleRemoveNoticeType(); handleToggleRemoveNoticeType();}}>
                    <FontAwesomeIcon icon={faArrowLeftLong} className="remove-notice-type-icon" /> 
                    <span className="remove-notice-type-text">Close</span>
                </button>
        <div className="remove-notice-input-container">
            {/* Blank Notification Message */}
            {showBlankNotificationMessage && (
                <div className="blank-notification-message">
                    Please select a notification type!
                </div>
            )}

            {/* Success Message */}
            {showSuccessMessage && (
                <div className="success-message">
                    Notification type removed!
                </div>
            )}

             {/* Fail Message */}
            {showFailMessage && (
                <div className="fail-message">
                    Notification type could not be removed!
                </div>
            )}
                
                 {/* remove Notification Type */}
            <div className="remove-notice-input">
              

            <div className="remove-dropdown">
                <div className="remove-header" onClick={toggleDropdown}  role="button"  tabIndex="0" >
                <span className="remove-text">{value || placeholder}</span>
                <span className={`remove-custom-arrow ${isOpen ? 'rotate' : ''}`}></span>
            </div>
                {isOpen && (
                     <ul className={`remove-options ${isOpen ? 'open' : 'closed'}`}>
                    {React.Children.map(children, (child) => (
                    <li
                        key={child.props.value}
                        className="remove-option"
                        onClick={() => handleOptionClick(child.props.value)}
                    >
                        {child.props.children}
                    </li>
                    ))}
                    </ul>
                )}
            </div>

                <button onClick={removeNotificationType}>
                    <span className="remove-notice-text">Remove</span>
                    <FontAwesomeIcon icon={faMinus} className="remove-notice-button-icon"/> 
                </button>
            </div>
            </div>
    </div>
        );
    };

    RemoveNoticeType.Option = ({ value, children }) => {
        return <>{children}</>;
      };

export default RemoveNoticeType;