import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useElements } from '@stripe/react-stripe-js';
import '../../styles/MainNotice/StripePaymentForm.css';
import { div } from 'framer-motion/client';
import SuccessIconWithBurst from './SuccessIconWithBurst';

// Load Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripePaymentForm = ({  isSubscriptionVisible, toggleSubscription }) => {

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    // Extract payment status from URL and set subscription visibility based on the URL parameter
    const params = new URLSearchParams(window.location.search);
    const status = params.get('status');

    if (status) {
      setPaymentStatus(status);
    }

  }, []);

  const handleProceedToPayment = async () => {
    if (selectedPlan) {
      console.log(selectedPlan);
      try {
        // Fetch the clientSecret from your backend
        const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            planType: selectedPlan,
          }),
          credentials: 'include'
        });
        const data = await response.json();

        console.log("data", data);
        console.log("data.Id",data.id);
        if (data.id) {
          // Redirect to Stripe's Checkout page
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({
            sessionId: data.id,
          });

          if (error) {
            console.error('Stripe Checkout error:', error);
          }
        } else {
          console.error('Failed to create checkout session');
        }
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    }
  };


  const closeModal = () => {
    setPaymentStatus(null);
  };

  return (
    <>
  <div className={`subscription-select-container ${isSubscriptionVisible ? 'expanded' : 'collapsed'}`}>
  <button className="subscription-select-header" onClick={toggleSubscription}>
    <FontAwesomeIcon icon={faArrowLeftLong} className="subscription-select-icon" />
    <span className="subscription-select-text">Close</span>
  </button>
    <SubscriptionOptions 
      selectedPlan={selectedPlan} 
      setSelectedPlan={setSelectedPlan} 
      handleProceedToPayment={handleProceedToPayment}
      isSubscriptionVisible={isSubscriptionVisible} 
      toggleSubscription={toggleSubscription}
    />
    </div>
   {paymentStatus && (
      <div className="payment-status-modal">
        <div className="payment-status-content">
          <div className="icon-container">
            {paymentStatus === 'success' ? (
              <SuccessIconWithBurst />
            ) : (
              <div className="cancel-icon"></div>
            )}
          </div>
          {paymentStatus === 'success' ? (
            <div>
              <h2>Payment Successful!</h2>
              <p>Thank you for subscribing to our service.</p>
              <button className="success-close-button" onClick={closeModal}>
                OK
              </button>
            </div>
          ) : (
            <div>
              <h2>Payment Canceled</h2>
              <p>It seems like the payment process was canceled. Please try again if needed.</p>
              <button className="cancel-close-button" onClick={closeModal}>
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    )}
    </>
  );
};


const SubscriptionOptions = ({ isSubscriptionVisible, toggleSubscription, selectedPlan, setSelectedPlan, handleProceedToPayment}) => {


  const handlePlanChange = (plan) => {
    // If the plan is already selected, deselect it; otherwise, select the new plan
    setSelectedPlan(selectedPlan === plan ? null : plan);
  };

  return (
    <div className={`subscription-select-container ${isSubscriptionVisible ? 'expanded' : 'collapsed'}`}>
       <button className="subscription-select-header" onClick={toggleSubscription}>
        <FontAwesomeIcon icon={faArrowLeftLong} className="subscription-select-icon" />
        <span className="subscription-select-text">Close</span>
      </button>
  <div className="subscription-select-title">
    <h3>Choose Your Subscription Plan</h3>
    <h4>Let's get started! Choose a plan that is tailored on your needs</h4>

<div className="subscription-select-options">
      <div
            className={`subscription-select-option ${selectedPlan === 'essential' ? 'selected' : ''}`}
            onClick={() => handlePlanChange('essential')}
          >
    <div className="checkmark-icon"></div>
    <h3>Essential Plan</h3>
    <p className="price">$9.99/month</p>
    <p>Suitable for individuals or small businesses with limited messaging needs. Includes:</p>
    <ul className="checkmark-list">
      <li>Up to 500 messages per month</li>
      <li>One registered phone number</li>
      <li>Email support</li>
    </ul>
    </div>

    <div
            className={`subscription-select-option ${selectedPlan === 'growth' ? 'selected' : ''}`}
            onClick={() => handlePlanChange('growth')}
          >
    <div className="checkmark-icon"></div>
    <h3>Growth Plan</h3>
    <p className="price">$29.99/month</p>
    <p>Ideal for growing businesses with moderate messaging needs. Includes:</p>
    <ul className="checkmark-list">
      <li>Up to 2,000 messages per month</li>
      <li>One registered phone number</li>
      <li>Priority email support</li>
    </ul>
    </div>

    <div
            className={`subscription-select-option ${selectedPlan === 'enterprise' ? 'selected' : ''}`}
            onClick={() => handlePlanChange('enterprise')}
          >
    <div className="checkmark-icon"></div>
    <h3>Enterprise Plan</h3>
    <p className="price">$49.99/month</p>
    <p>Designed for larger businesses with higher messaging demands. Includes:</p>
    <ul className="checkmark-list">
      <li>Up to 5,000 messages per month</li>
      <li>One registered phone number</li>
      <li>Priority email and phone support</li>
    </ul>
    </div>
</div>
<button
        className="proceed-payment-button"
        onClick={handleProceedToPayment}
        disabled={!selectedPlan} 
      >
        Proceed to Payment
      </button>
</div>

</div>
  );
};


export default StripePaymentForm;
