import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import '../../styles/MainNotice/BuzzMessage.css'; // Import the CSS file

const BuzzMessage = ({ value, onChange, placeholder, onClick, disabled, className, children }) => {
  const textAreaRef = useRef(null);
  
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]); // Run effect whenever `value` changes


  return (
    <div className="message-input-container">
      <textarea
        ref={textAreaRef}
        className="message-input-box"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={1} // Start with one row
      />
      <button onClick={onClick} disabled={disabled} className="send-button">
        <FontAwesomeIcon icon={faPaperPlane} className="send-message-icon" /> 
        {children}
      </button>
    </div>
  );
};

export default BuzzMessage;