import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../config/msalConfig';
import { useMsal } from '@azure/msal-react'; // Use MSAL React hooks
import { SelectCustomerType } from '../components/CustomerType';
import '../styles/CustomerType/Container.css';

const CustomerType = () => {
    const navigate = useNavigate();
    const { instance } = useMsal(); // Access the MSAL instance from context
    const [customerType, setCustomerType] = useState('');
    const [error, setError] = useState(''); // State to handle errors
    const [isLoggingIn, setIsLoggingIn] = useState(false); // State to manage loading state

    // Process the response from Azure AD B2C
   
    const handleCustomerTypeSelect = async (type) => {
        setCustomerType(type);
        console.log(`Selected customer type: ${type}`);

        if (type === 'business') {
            console.log(`Redirecting to Azure AD B2C login`);
            setIsLoggingIn(true); // Set loading state to true
            setError(''); // Clear previous errors

            try {
                // Initiate the login process and redirect to Azure AD B2C login page
                await instance.loginRedirect({ scopes: loginRequest.scopes });
            } catch (error) {
                console.error("Login failed: ", error);
                setError('An error occurred during login. Please try again.'); // Set error message
            } finally {
                setIsLoggingIn(false); // Set loading state to false
            }
        } else {
            navigate('/businessSelect'); // Redirect to businessSelect for other customer types
        }
    };

    return (
        <div className="customertype-container">
            <header className="wrapper"> 
                <svg>
                    <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                        Bulk Buzz
                    </text>
                </svg>
                <svg>
                    <text x="50%" y="10%" dy=".1em" textAnchor="middle">
                        What's All The Buzz?
                    </text>
                </svg>
            </header>    
            <div className="select-container">
                <h1 className="customer-type-header">Subscribe to Notifications</h1>
                {error && <div className="error-message">{error}</div>} {/* Display error if present */}
                {isLoggingIn && <div className="loading-message">Logging in...</div>} {/* Display loading message */}
                <SelectCustomerType onSelect={handleCustomerTypeSelect} />      
            </div>    
        </div>
    );
};

export default CustomerType;
