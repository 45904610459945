import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import '../../styles/MainNotice/TextCounter.css';

const TextCounter = forwardRef(({ customerKey }, ref) => {
  const [textRemaining, setTextRemaining] = useState(0); // Current remaining texts
  const [textLimit, setTextLimit] = useState(500); // Default text limit
  const [animationTarget, setAnimationTarget] = useState(0); // Target value for animation
  const [tooltipVisible, setTooltipVisible] = useState(false); // Tooltip visibility

  const fetchTextRemaining = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get-text-remaining?customerKey=${customerKey}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const newRemaining = data.remainingTexts || 0; // Get the new remaining texts
      setTextLimit(data.textLimit || 500); // Update text limit from the backend
      animateCounter(newRemaining); // Trigger animation
    } catch (error) {
      console.error('Error fetching text remaining:', error);
      setTextRemaining(0);
    }
  };

  const animateCounter = (newRemaining) => {
    setAnimationTarget(newRemaining); // Set target value
    const totalFrames = 60; // Number of animation frames
    const step = (textRemaining - newRemaining) / totalFrames; // Step size per frame
    let currentFrame = 0;

    const animate = () => {
      if (currentFrame < totalFrames) {
        const newValue = Math.round(textRemaining - step * currentFrame);
        setTextRemaining(newValue);
        currentFrame++;
        requestAnimationFrame(animate); // Request the next animation frame
      } else {
        setTextRemaining(newRemaining); // Ensure the final value matches
      }
    };

    animate(); // Start animation
  };

  useImperativeHandle(ref, () => ({
    updateCounter: fetchTextRemaining,
  }));

  useEffect(() => {
    if (customerKey) {
      fetchTextRemaining(); // Fetch initial value
    }
  }, [customerKey]);

  const progressPercentage = Math.max(0, Math.min(100, (textRemaining / textLimit) * 100)); // Calculate percentage

  // Determine the progress bar color
  const getProgressBarColor = () => {
    if (progressPercentage <= 20) {
      return 'red';
    } else if (progressPercentage <= 50) {
      return '#F7C843';
    } else {
      return '#4CAF50';
    }
  };

  const toggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  return (
    <div
      className="circular-progress-container"
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
    >
      <div className="circular-progress">
        <svg className="progress-ring" width="120" height="120">
          <defs>
            <pattern id="progress-texture" patternUnits="userSpaceOnUse" width="10" height="10">
              <rect width="10" height="10" fill="white" />
              <path d="M0,0 L10,10 M10,0 L0,10" stroke="lightgray" strokeWidth="1" />
            </pattern>
          </defs>
          <circle
            className="progress-ring-circle-bg"
            stroke="url(#progress-texture)"
            strokeWidth="10"
            fill="transparent"
            r="50"
            cx="60"
            cy="60"
          />
          <circle
            className="progress-ring-circle"
            stroke={getProgressBarColor()}
            strokeWidth="10"
            fill="transparent"
            r="50"
            cx="60"
            cy="60"
            style={{
              strokeDasharray: `${2 * Math.PI * 50}`,
              strokeDashoffset: `${2 * Math.PI * 50 * (1 - progressPercentage / 100)}`,
            }}
          />
        </svg>
        <div className="progress-text">{textRemaining}</div>
      </div>
      <div className="progress-label">
        <span className="label-text">Remaining Texts</span>
      </div>
      {tooltipVisible && (
        <div
          className="progress-tooltip"
          style={{ backgroundColor: getProgressBarColor() }}
        >
          {textRemaining} / {textLimit} Texts Remaining
        </div>
      )}
    </div>
  );
});

export default TextCounter;
