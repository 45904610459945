import React, {useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { QRCodeCanvas } from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import '../../styles/MainNotice/SubscriptionQR.css'; 

const SubscriptionQR = ({isQRCodeVisible, toggleQRCode}) => {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const customerKey = query.get('customerKey'); 
  const [businessInfo, setBusinessInfo] = useState({
    fstr_business_name: '',
    fstr_zip: '',
});

const url = `https://bulkbuzz.net/subscribe?customerKey=${customerKey}&zip=${businessInfo.fstr_zip}&businessName=${encodeURIComponent(businessInfo.fstr_business_name)}`;

  useEffect(() => {
    const fetchBusinessInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/get-business-info`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });
          if (response.ok) {
            const data = await response.json();
            console.log("Fetch Business Info Result: ",  data);
            setBusinessInfo(data);
          } else {
            console.error('Failed to fetch business information.');
          }
        } catch (error) {
          console.error('Error fetching business information:', error);
        }
      };
  
      if (isQRCodeVisible) {
        fetchBusinessInfo();
        
      }
    }, [isQRCodeVisible]);


    const qrCodeRef = useRef();
    const downloadQRCode = () => {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const imageURL = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'subscription-qr-code.png';
        link.click();
      }
    };

  return (


    <div className={`QR-code-container ${isQRCodeVisible ? `expanded` : `collapsed`}`}>
        <button className="QR-code-header" onClick={toggleQRCode}>
            <FontAwesomeIcon icon={faArrowLeftLong} className="QR-code-icon" /> 
                <span className="QR-code-text">Close</span>
        </button>

    <div className='QR-code-input-container'>
      <h3>Scan this QR Code to Subscribe</h3>
      <div ref={qrCodeRef}>
      <QRCodeCanvas value={url} size={300} />
      </div>
      <button onClick={downloadQRCode}>
      <FontAwesomeIcon icon={faQrcode} className="QR-code-input-button-icon1"/> 
      <span className="QR-code-input-button-text">Download QR Code</span>
      <FontAwesomeIcon icon={faQrcode} className="QR-code-input-button-icon2"/> 
      </button>
    </div>

    </div>
  );
};

export default SubscriptionQR;