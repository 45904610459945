import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import msalConfig from './config/msalConfig';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalConfig);

const initializeMsal = async () => {
    try {
        await msalInstance.initialize(); // Ensure MSAL is initialized
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]); // Set active account if one exists
        }
    } catch (error) {
        console.error("MSAL initialization error: ", error);
    }
};

// Listen for sign-in events and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

initializeMsal().then(async () => {
    root.render(
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    );
}).catch((error) => {
    console.error("Error during MSAL initialization: ", error);
});
