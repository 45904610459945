import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faBell, faBars, faPlus, faMinus, faChartLine, faFileInvoiceDollar, faGears, faFileLines, faBuilding, faQrcode } from '@fortawesome/free-solid-svg-icons';
import '../../styles/MainNotice/Sidebar.css'; 

const Sidebar = ({ toggleSideBar, isSideBarOpen, toggleSentNotifications, toggleNoticeTypeOptions, showNoticeTypeOptions, toggleAddNoticeType, toggleRemoveNoticeType, toggleBusinessInfoForm,
    toggleQRCode, toggleSubscription}) => {


    return (
        <div className={`sidebar-container ${isSideBarOpen ? 'expanded' : 'collapsed'}`}>
            <button className="expand-button" onClick={toggleSideBar}>
            <div className="parent-icon" >
                <FontAwesomeIcon icon={faBars}/>
            </div>
            </button>
            {/* Add more buttons here as needed */}

            <button className="parent-button" onClick={toggleBusinessInfoForm}>
                <div className="parent-icon" >
                <FontAwesomeIcon icon={faBuilding} transform="right-4" />
                <FontAwesomeIcon icon={faFileLines} transform="shrink-2 down-4 left-3" color= "#ccc"/>
                </div>
                <span className="parent-text">Business Information</span>
            </button>        

            <button className="parent-button" onClick={toggleSentNotifications}>
                <div className="parent-icon">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                </div>
                <span className="parent-text">Sent Notifications</span>
            </button>
            
            <button className="parent-button"  onClick={toggleNoticeTypeOptions}>
                <div className="parent-icon">
                    <FontAwesomeIcon icon={faBell} />          
                </div>
                <span className="parent-text">Manage Notification Types</span>
            </button>

            {/* Conditional rendering for child buttons */}
                       <button className={`child-button ${showNoticeTypeOptions ? 'show' : ''}`}  onClick={toggleAddNoticeType}>
                       <div className="child-icon">
                            <FontAwesomeIcon icon={faBell} transform="right-4" />
                            <FontAwesomeIcon icon={faPlus} transform="shrink-6 up-7 "/>
                       </div>
                       <span className="child-text">Add Notification Type</span>
                     </button>
                     <button className={`child-button ${showNoticeTypeOptions ? 'show' : ''}`} onClick={toggleRemoveNoticeType}>
                       <div className="child-icon">
                       <FontAwesomeIcon icon={faBell} transform="right-4" />
                       <FontAwesomeIcon icon={faMinus} transform="shrink-6 up-7 "/>
                       </div>
                       <span className="child-text">Remove Notification Type</span>
                     </button>

            <button className="parent-button" onClick={toggleQRCode}>
                <div className="parent-icon">
                    <FontAwesomeIcon icon={faQrcode} />
                </div>
                <span className="parent-text">QR Code</span>
            </button>

            <button className="parent-button">
                <div className="parent-icon">
                    <FontAwesomeIcon icon={faChartLine} />
                </div>
                <span className="parent-text">Subscriber Statistics</span>
            </button>
            <button className="parent-button" onClick={toggleSubscription}>
                <div className="parent-icon">
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </div>
                <span className="parent-text">Subscription</span>
            </button>
            <button className="parent-button">
                <div className="parent-icon">
                    <FontAwesomeIcon icon={faGears} />
                </div>
                <span className="parent-text">Settings</span>
            </button>
        </div>
    );
};

export default Sidebar;
