import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Subscribe/Animation.css'; 
import '../styles/BusinessSelect/BusinessSelect.css'; 

const BusinessSelect = () => {
    const [businesses, setBusinesses] = useState([]);
    const [zipCode, setZipCode] = useState(''); // State for zip code
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const navigate = useNavigate(); // Use navigate from react-router

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/businesses${zipCode ? `?zip=${zipCode}` : ''}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setBusinesses(data);
            } catch (error) {
                console.error('Error fetching businesses:', error);
            }
        };

        fetchBusinesses();

    }, [zipCode]);

    const handleBusinessSelect = () => {
        
        const selectedBusinessObj = businesses.find(
            (business) => business.flng_customer_key === Number(selectedBusiness)
        );

        if (selectedBusiness) {
            navigate(`/subscribe?customerKey=${selectedBusinessObj.flng_customer_key}&zip=${selectedBusinessObj.fstr_zip}&businessName=${selectedBusinessObj.fstr_business_name}`);
        } else {
            alert('Please select a business and enter a zip code to subscribe.');
        }
    };

    return (
        <div className="business-select-container">
            <header className="wrapper">
                <svg>
                    <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                        Bulk Buzz
                    </text>
                </svg>
                <svg>
                    <text x="50%" y="10%" dy=".1em" textAnchor="middle">
                        What's All The Buzz?
                    </text>
                </svg>
            </header>      

            <select className="business-select-dropdown"
              value={selectedBusiness} 
              onChange={(e) => setSelectedBusiness(e.target.value)}

            > 
            <option value="">Select a Business</option>
            {businesses.map((business) => (
                <option key={business.flng_customer_key} value={business.flng_customer_key}>
                    {`${business.fstr_business_name}`} 
                </option>
                ))}
            </select>

            <input
                type="text"
                placeholder="Enter Zip Code"
                value={zipCode}
                onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*$/.test(input)) { // Allow only numbers
                        setZipCode(input);
                    }
                }}
                className="business-select-zip-input" 
                pattern="\d*" // Only allows numbers
            />
            <button className = "business-select-button" onClick={handleBusinessSelect}>Proceed to Subscribe</button>
        </div>
    );
};

export default BusinessSelect;
