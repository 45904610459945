import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useMsal} from '@azure/msal-react'; 
import CustomerType from './pages/CustomerType';
import MainNotice from './pages/MainNotice';
import BusinessSelect from './pages/BusinessSelect';
import Subscribe from './pages/Subscribe';
import AuthRedirect from './pages/authRedirect'; 
import { loginRequest } from './config/msalConfig';
import { useEffect, useState } from 'react';
import TermsAndConditions from './components/Subscribe/TermsAndConditions';

const PrivateRoute = ({ children }) => {
  const { instance } = useMsal(); // Get the MSAL instance
  const activeAccount = instance.getActiveAccount();
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const checkAccount = async () => {
      if (!activeAccount) {
        await instance.loginRedirect({
          scopes: loginRequest.scopes, 
        });
      } else {
        setLoading(false); // Account exists, stop loading
      }
    };

    checkAccount();
  }, [activeAccount, instance]);

  // Show a loading message or spinner if still checking
  if (loading) {
    return <div>Loading...</div>; // Replace with your preferred loading indicator
  }

  // If there is an active account, render the children components
  return activeAccount ? children : null;
};

const App = () => {
  return (
      <Router>
        <Routes>
          {/* Public Route for customers */}
          <Route path="/customerType" element={<CustomerType />} />

          <Route path="/businessSelect" element={<BusinessSelect />} />

          <Route path="/subscribe" element={<Subscribe />} />

          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

          <Route path="/authRedirect" element={<AuthRedirect />} /> 
          {/* Private Route for authenticated users */}
          <Route path="/dashboard" element={<PrivateRoute><MainNotice /></PrivateRoute>} />

          {/* Default route */}
          <Route path="*" element={<Navigate to="/customerType"/>} />
        </Routes>
      </Router>
  );
};

export default App;
