import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import '../../styles/MainNotice/SentNotifications.css'; 

const SentNotifications = ({ isSentNotificationsVisible, toggleSentNotifications, onUpdateSentNotifications }) => {
    const [sentNotifications, setSentNotifications] = useState([]);
    const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);

    useEffect(() => {

        const fetchSentNotifications = async () => {
          setIsLoadingNotifications(true);
          setSentNotifications([]); // Clear notifications to trigger the reloading animation
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/sent-notifications`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include' // Include cookies with request
            });
      
            // Check if the response is OK (status code 200)
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
      
            const data = await response.json();
            setSentNotifications(data);
            console.log("Sent Notifications Data: ",data);
          } catch (error) {
            console.error('Error fetching sent notifications:', error);
        } finally {
            setIsLoadingNotifications(false);
        }
        };
      
        fetchSentNotifications();

    
      }, [isSentNotificationsVisible]);
    



    return (
    <div className={`sent-notifications-container ${isSentNotificationsVisible ? `expanded` : `collapsed`}`}>
            <button className="sent-notifications-header" onClick={toggleSentNotifications}>
                <FontAwesomeIcon icon={faArrowLeftLong} className="sent-notifications-icon" /> 
                <span className="sent-notifications-text">Close</span>

            </button>
            <div className="scroll-box">
                {sentNotifications.length === 0 ? (
                    <div className= "no-notifications-text" >No notifications sent yet.</div>
                ) : (
                    sentNotifications.map((message, index) => (
                        <div key={index} className="notification-item">
                            <h3 className="notification-subject">{message.fstr_subject}</h3>
                            <p className="notification-description">{message.fstr_description}</p>
                            <span className="timestamp">{new Date(message.fdtm_sent).toLocaleString()}</span>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SentNotifications;


