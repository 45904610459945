import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faPen, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import '../../styles/MainNotice/BusinessInfoForm.css'; 

const BusinessInfoForm = ({isBusinessInfoFormVisible, toggleBusinessInfoForm}) => {

    const [initialBusinessInfo, setInitialBusinessInfo] = useState({
        fstr_business_name: '',
        fstr_street: '',
        fstr_city: '',
        fstr_state: '',
        fstr_zip: '',
      });

  // State to hold business information
  const [businessInfo, setBusinessInfo] = useState({
        fstr_business_name: '',
        fstr_street: '',
        fstr_city: '',
        fstr_state: '',
        fstr_zip: '',
    });

  // State to manage if fields are editable
  const [isEditable, setIsEditable] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!isBusinessInfoFormVisible) {
      // Reset to original fetched data when the form is closed
      setBusinessInfo(initialBusinessInfo);
    }
  }, [isBusinessInfoFormVisible, initialBusinessInfo]);

  useEffect(() => {
    const fetchBusinessInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/get-business-info`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });
          if (response.ok) {
            const data = await response.json();
            console.log("Fetch Business Info Result: ",  data);
            setInitialBusinessInfo(data);
            setBusinessInfo(data);
          } else {
            console.error('Failed to fetch business information.');
          }
        } catch (error) {
          console.error('Error fetching business information:', error);
        }
      };
  
      if (isBusinessInfoFormVisible) {
        fetchBusinessInfo();
        setIsEditable(false);
        
      }
    }, [isBusinessInfoFormVisible]);

   // Function to handle form input changes
   const handleInputChange = (e) => {

    const { name, value } = e.target;
    setBusinessInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };


  // Function to toggle edit mode
  const handleEditClick = () => {
    if (isProcessing) return; // Prevent rapid clicking issues
    setIsProcessing(true);
    setIsEditable(true);
    setTimeout(() => {
        setIsProcessing(false);
      }, 2000); // Wait briefly to prevent immediate re-render issues
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedBusinessInfo = {
        fstr_business_name:  businessInfo.fstr_business_name || document.querySelector('input[name="fstr_business_name"]').value,
        fstr_street:  businessInfo.fstr_street || document.querySelector('input[name="fstr_street"]').value,
        fstr_city: businessInfo.fstr_city || document.querySelector('input[name="fstr_city"]').value,
        fstr_state:  businessInfo.fstr_state || document.querySelector('input[name="fstr_state"]').value,
        fstr_zip: businessInfo.fstr_zip || document.querySelector('input[name="fstr_zip"]').value,
      };

        console.log("Updated Business Info",updatedBusinessInfo);

    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/insert-business-info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedBusinessInfo),
          credentials: 'include',
        });

        console.log("insert-business-info Response: ", response)

        if (response.ok) {
          const data = await response.json();
          console.log('Successfully added business information.');
          setIsEditable(false);
          setBusinessInfo(data);
          setInitialBusinessInfo(data);
        } else {
          console.error('Failed to add business information.');
        }

      } catch (error) {
        console.error('Error adding business information:', error);
      }
    };

  
return (
    
    <div className={`business-info-form ${isBusinessInfoFormVisible ? `expanded` : `collapsed`}`}>
        <button className="business-info-form-header" onClick={toggleBusinessInfoForm}>
            <FontAwesomeIcon icon={faArrowLeftLong} className="business-info-form-icon" /> 
                <span className="business-info-form-text">Close</span>
        </button>

    <form className="form" onSubmit={handleSubmit } autoComplete="off">
            <p className="title">Business Information</p>
            <p className="message">Add your business details to let subscribers find you</p>

        <label>
            <input
                className="input"
                type="text"
                name="fstr_business_name"
                value={businessInfo.fstr_business_name}
                onChange={handleInputChange}
                placeholder=""
                required
                readOnly={!isEditable}
                autoComplete="new-password"
            />
          <span>Business Name</span>
        </label>

      <label>
         <input
            className="input"
            type="text"
            name="fstr_street"
            value={businessInfo.fstr_street}
            onChange={handleInputChange}
            placeholder=""
            required
            readOnly={!isEditable}
            autoComplete="new-password"
          />
        <span>Street</span>
      </label>

      <div className="flex">
      <label>
        <input
            className="input"
            type="text"
            name="fstr_city"
            value={businessInfo.fstr_city}
            onChange={handleInputChange}
            placeholder=""
            required
            readOnly={!isEditable}
            autoComplete="new-password"
        />
        <span>City</span>
      </label>

      <label>
        <input
            className="input"
            type="text"
            name="fstr_state"
            value={businessInfo.fstr_state}
            onChange={handleInputChange}
            placeholder=""
            required
            readOnly={!isEditable}
            autoComplete="new-password"
        />
        <span>State</span>
      </label>

      <label>
        <input
            className="input"
            type="text"
            name="fstr_zip"
            value={businessInfo.fstr_zip}
            onChange={handleInputChange}
            placeholder=""
            required
            readOnly={!isEditable}
            autoComplete="new-password"
        />
        <span>Zip Code</span>
      </label>

      </div>


      {!isEditable ? (
          <button type="button" className="edit-button" onClick={handleEditClick}>
            Edit
            <FontAwesomeIcon icon={faPen} className="edit-button-icon" />
          </button>
        ) : (
          <button  type="button" className="submit" onClick={handleSubmit}>
            Submit
            <FontAwesomeIcon icon={faCircleCheck} className="submit-icon" />
          </button>
        )}
    
    </form>
    </div>

);

};

export default BusinessInfoForm;