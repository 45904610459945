import React, { useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faPlus,faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import '../../styles/MainNotice/AddNoticeType.css'; 

const AddNoticeType = ({ isAddNoticeTypeVisible, toggleAddNoticeType, onAddSuccess }) => {

    const [newType, setNewType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State to manage success message visibility
    const [showFailMessage, setShowFailMessage] = useState(false); // State to manage fail message visibility
    const [showBlankNotificationMessage, setShowBlankNotificationMessage] = useState(false); // State to manage fail message visibility


    const createNotificationType = async () => {
        if (newType.trim() === '') {
            setShowBlankNotificationMessage(true); //Show Blank Notification Message

            setTimeout(() => {
                setShowBlankNotificationMessage(false);
            }, 3000);

            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/add-notice-type`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ notificationType: newType }),
                credentials: 'include', // Include credentials with the request
            });

            console.log("Add-Notice-Type Response: ", response)

            if (response.ok) {
                const createdType = await response.json();
                setNewType('');
                setShowSuccessMessage(true); // Show success message

                onAddSuccess();

                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 3000);


            } else if (response.status === 400) {
                setShowFailMessage(true); // Show Fail message

                setTimeout(() => {
                    setShowFailMessage(false);
                }, 3000);

                
            } else {
                console.error("Failed to create notification type");
            }
        } catch (error) {
            console.error('Error creating notification type:', error);
        }
    };

    const handleToggleAddNoticeType = () => {
        setNewType(''); // Clear input when toggling the container
    };

    return (
        <div className={`add-notice-type-container ${isAddNoticeTypeVisible ? `expanded` : `collapsed`}`}>
                <button className="add-notice-type-header" onClick={() => {toggleAddNoticeType(); handleToggleAddNoticeType();}}>
                    <FontAwesomeIcon icon={faArrowLeftLong} className="add-notice-type-icon" /> 
                    <span className="add-notice-type-text">Close</span>
                </button>
        <div className="add-notice-input-container">
            {/* Blank Notification Message */}
            {showBlankNotificationMessage && (
                <div className="blank-notification-message">
                <FontAwesomeIcon icon={faTriangleExclamation} />
                    Please enter a valid notification type!
                </div>
            )}

            {/* Success Message */}
            {showSuccessMessage && (
                <div className="success-message">
                    Notification type added!
                </div>
            )}

             {/* Fail Message */}
            {showFailMessage && (
                <div className="fail-message">
                    Notification type already exists!
                </div>
            )}
                
                 {/* Create New Notification Type */}
            <div className="add-notice-input">
                <input
                    type="text"
                    value={newType}
                    onChange={(e) => setNewType(e.target.value)}
                    placeholder="Enter new notification type..."
                />
                <button onClick={createNotificationType}>
                    <span className="add-notice-text">Add</span>
                    <FontAwesomeIcon icon={faPlus} className="add-notice-icon"/> 
                </button>
            </div>
        </div>

        </div>
        );
    };

export default AddNoticeType;